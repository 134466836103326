html,body {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gridBodyRow > td div{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 2em;
}

@media screen and (min-width: 974px) {
    #profileInfo {
        width: 50%;
    }
}

@media screen and (max-width: 973px)
{
    #profileName {
        font-weight: bold;
        font-size: 20px !important;

    }

    #profileInfo {
        width: 60%;
    }
}

@media screen and (max-width: 873px)
{
    #profileInfo {
        width: 68%;
    }

    .inlineSegment {
        font-size: 1em !important;
    }
}

@media screen and (max-width: 667px) {
    #profileInfo {
        width: 80%;
    }

    .inlineSegment {
        display: none !important;
    }
}

@media screen and (min-width:791px) {
    #appShortName{
        display: none !important;
    }

    #appLongName {
        display: block !important;
        padding-top: 21px;
    }
}

@media screen and (max-width: 790px) {
    /*.topNavMenu .item{
        width: 114px;
        font-size: 15px;
    }
    .topNavMenu .ui.menu .menu{
        margin: 7px;
    }
    .topNavMenu .right.menu{
        margin-left: unset !important;
        margin-left: -43px !important;
        margin-right: 49px !important;
    }
    .topNavMenu .right.menu i{

        margin-left: 34px;
        font-size: 26px;
    }
    .topNavMenu .right.menu > div{
        display: contents;
    }
    
    .ui.search.dropdown>.text{
        margin-left: 10px;
    }*/

    #appLongName {
        display: none;
    }
    #appShortName {
        display: inline-block !important;
        padding-top: 21px;
    }
    .ui[class*="top fixed"].menu{
        height: 60px;
    }

}

@media only screen and (max-width: 767px) {
    .ui.fluid.container {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.topNavMenu {
    border-radius: 0 !important;
}

/* Side Nav Styling */
.topSideNav {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 60px;
    bottom: 0;
    left: 0;
    width: 250px;
    background: #333333;
    flex: 1;
    overflow: visible;
    z-index: 2 !important;
}

.dropDownCaret {
    padding-left: 25% !important;
}

.subMenuChosen {
    background-color: transparent !important;
    color: rgba(11,0,0,.87) !important;
    font-size: 17px !important;
    padding-left: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    min-width: 100% !important;
}

.sideSubMenuTop {
    background-color: #333333 !important;
    font-size: 17px !important;
    position: static !important;
    border-bottom: 1px solid #404040 !important;
    border-top: none !important;
}

.sideSubMenuTopChosen {
    background-color: red !important;
    font-size: 17px !important;
    position: static !important;
    border-bottom: 1px solid #404040 !important;
    border-top: none !important;
}

/*.ui.inverted.menu .dropdown.item.sideSubMenuTopChosen:after {
    background-color: white !important;
}*/

.sideMenuItem {
    border-bottom: 1px solid #404040 !important;
    border-top: none !important;
}

.sideSubMenuTop.dropdown.active.item,
.sideSubMenuTop.link.active.item,
.sideSubMenuTop.a.active.item,
.sideSubMenuTop.active.item {
    background-color: red !important;
}

.sideSubMenuTop.dropdown.item:hover,
.sideSubMenuTop.link.item:hover,
.sideSubMenuTop.a.item:hover,
.sideSubMenuTop.item:hover {
    background-color: #595959 !important;
}

.subMenuChosen.dropdown.item:hover,
.subMenuChosen.link.item:hover,
.subMenuChosen.a.item:hover,
.subMenuChosen.item:hover {
    /*background: #595959 !important;*/
}

.subMenuBox {
    opacity: 1;
    z-index: 2 !important;
    position: absolute !important;
}

/*.subItemWithSubItems {
    display: inline-block !important;
    padding: 0 !important;
    margin: 0 !important;
}*/


/*.tableFixHead table {position: relative;}
.tableFixHead thead tr#first th { position: sticky !important; top: -3px; }
.tableFixHead thead tr#second td { position: sticky !important; top: 17px; }*/



.navCalendar {
    flex: 0 0 auto;
    min-height: 200px;
}

.calendarBody {
    background-color: #595959 !important;
}

.sideMenuNav {
    flex: 1;
    height: calc(51vh);
    overflow-y: scroll;
    overflow-x: hidden;
}

.disable-scrollbars::-webkit-scrollbar {
    width: 0px;
    display: none;
    background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}

.sideMenuNav a {
    /*padding: 8px 8px 8px 32px;*/
    text-decoration: none;
    font-size: 17px;
    color: #818181;
    display: inline-block;
    transition: 0.3s;
}

.sideMenuNav a:hover {
    color: #f1f1f1;
}

.sideNavItem:hover {
    background-color: grey;
}

.sideNavItem:active {
    background-color: red;
}

.sideNavAppName {
    position: relative;
    display: inline-block;
    font-size: 36px;
}

.sideMenu {
    width: 100% !important;
    height: 100% !important;
}

a.item.sideMenuItem {
    background-color: #333333 !important;
}

div.item.sideMenuItem {
    background-color: #333333 !important;
}

a.item.subSideMenuItem {
    background-color: #333333 !important;
    padding-left: 10px;
}

a.active.item.sideMenuItem {
    background-color: red !important;
}

.sideMenuItem.item:hover {
    background-color: #595959 !important;
}

.ui.inverted.pointing.menu .active.item.sideMenuItem:after {
    background-color: white !important;
}

.ui.inverted.pointing.menu .active.item.sideMenuItem:hover:after {
    background-color: white !important;
}

#mainContent {
    transition: margin-left .5s;
    padding: 16px;
    margin-left: 250px;
}

#topNav {
    height: 60px !important;
    transition: margin-left .5s;
}

.siteTopLevel {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
}

.loginTopLevel {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.siteContent {
    flex: 1 0 auto;
}

.footer {
    margin-top: 10px;
    padding-top: 5px;
    padding: 1.5rem;
    /*background-color: #a6a6a6;*/
}

.footer p {
    text-align: center;
}

.footerPadding {
    padding-left: 250px;
}

.gridContainer {
    /*overflow-x: hidden !important;
    height: 100% !important;
    display: flex !important;
    display: -webkit-flex !important;
    flex-direction: column !important;
    -webkit-flex-direction: column !important;*/
    overflow: auto;
    max-height: 60vh;
    min-height: 60vh;
}

.gridExportDiv {
    display: inline-block;
}

.table {
    margin-bottom: 0 !important;
}

.tracGrid {
    margin-bottom: 20px !important;
    border: 1px solid #d4d4d5 !important;
    border-top-left-radius: .28571429rem !important;
    border-top-right-radius: .28571429rem !important;
    /*width: 100% !important;
    height: 55vh !important;*/
}

/*.gridContainer > .tableBody {
    width: -webkit-fit-content !important;
    overflow: auto !important;
    flex: 1 !important;
    -webkit-flex: 1 !important;
}*/

.gridHeaders {
    --contentHeight: 42px !important;
}

.topTable {
    margin-top: 0 !important;
    min-height: 60vh !important;
    height: 60vh !important;
}

/*.divider{
    width:5px;
    height:auto;
    display:inline-block;
}*/

/*.gridBody {
    min-height: 55vh;
}*/

/*.gridBodyRow {
    overflow-x: scroll !important;
}*/

.gridBodyRow {
    max-height: 40px !important;
}

.gridSegment {
    overflow: auto !important;
    width: auto;
    height: auto;
    padding: 0 !important;
}

.gridDimmer {
    margin-top: 105px;/*14vh !important;*/
}

.letterDimmer {
    /*z-index: 1 !important;*/
}

.gridLoading {
    position: absolute !important;
    max-height: 60vh !important;
    /*left: 40vw !important;
    top: 20vh !important;*/
    /*position: sticky !important;
    left: 60% !important;
    top: 55% !important;*/
}

.tracGridHeader {
    margin-bottom: 25px !important;
}

.gridFilterCell {
    border-bottom: 1px solid rgba(34,36,38,.1);
}

/*.gridDropDownFilter {
    overflow: inherit !important;
}*/

.gridBody {
}

.gridNoDataRow {
    min-width: 100%;
}

.gridNoRows {
    position: absolute !important;
    max-height: 60vh !important;
    /*left: 50%;*/
}
.ui.checkbox {
    position: unset !important;

}
#customCheckbox{
   
    position: unset !important;
    top: unset !important;
    left: unset !important;
    opacity: 1 !important;  
    z-index: unset !important;
  
}

.gridHeaderDiv {
    background-color: #F97E64;
    padding: 0.5%;
}
.disableCheckBox{
    visibility: hidden;
}

.gridContainer thead tr:nth-child(1) th {
    position: sticky !important;
    top: 0;
    z-index: 0 !important;
}
.gridContainer thead tr:nth-child(2) th 
{
    position: sticky !important;
    top: var(--contentHeight);/*44px;*//*10.8%;*/
    z-index: 0 !important;
}


table.freezeColumn thead tr > th:nth-child(1),
table.freezeColumn thead tr > th:nth-child(2),
table.freezeColumn thead tr > th:nth-child(3) {
    position: -webkit-sticky;
    position: sticky !important;
    background-color: white;

    width: 100px;
    left: 0;
    max-width: 100px;
    z-index: 1 !important;
   
}
table.freezeColumn tbody tr > td:nth-child(1),
table.freezeColumn tbody tr > td:nth-child(3) {
   position: -webkit-sticky;
    position: sticky !important;
    background-color: white;
    width: 100px;
    
    max-width: 100px;
   
     left: 0px;  
} 
table.freezeColumn thead tr > th:nth-child(1) ,
table.freezeColumn thead tr > th:nth-child(2)
{
 position: sticky !important;  
 top: 0;
}



.view {
    margin: auto;
    width: 600px;
  }
  
  .wrapper {
    position: relative !important;
    /*overflow: auto important;*/
    border: 1px solid black;
    /*white-space: nowrap important;*/
  }

  .quarterlyReviewClearBtn{
    margin-bottom: 52px;
    margin-top: 32px;
  }
  .modalProp{
      width: 32% !important;
  }
  #contentId{
    font-size: 19px;
}
  

.gridContainer th {
    /*min-width: 150px !important;*/
}

.gridContainer th:hover {
    background: #f9fafb !important;
}


.gridContainer td {
    /*min-width: 150px !important;*/
}

.tableHeaderCell::before {
    cursor: col-resize;
    user-select: none;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 1em;
}

.tableHeaderCell:last-of-type::before {
    display: none;
}

.headerCell {
    z-index: 1 !important;
   
}

.utilityColumn {
    z-index: 1 !important;
    max-width: 75px !important;
}

.resizing {
    cursor: col-resize;
    user-select: none;
}

.clickableIcon {
    cursor: pointer;
}

#spaceLabel {
    background-color: #fff;
}

#loginHeader {
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    background-color: #fff;
}

.pt-title-row {
    padding: 5rem 5rem 0!important;
}

.pt-component-row {
    padding: 0 2rem 2rem!important;
}

.loginBox {
    margin-bottom: 80px !important;
}

.transparentButton {
    background-color: transparent !important;
    color: white !important;
}

.nonWhiteTransparentButton {
    background-color: transparent !important;
}

.datepickerinput {
    display: table !important;
    border: 1px solid rgba(0,0,0,0.1);
    background: #fff;
    padding: .67857143em 1em;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
    width: 100% !important;
}

/* Start of Profile Page Stylings */
.profileHeader {
    margin-bottom: 30px;
}

.profilePage {
    /*padding: 10px 50px 10px 50px;*/
}

.profileAvatar {
    width: 125px;
    height: 125px;
    font-size: 42px !important;
}

.userInfoSection {
    margin-bottom: 40px;
}

.inlineSegment {
    position: relative !important;
    display: inline-block !important;
}

.profileDivider {
    background-color: grey !important;
}

#profileInfo {
    /*width: 30%;*/
    margin-left: 40px;
}

#profileName {
    font-weight: bold;
    font-size: 24px;
}

.profileInfoHeader {
    display: inline-block;
    width: 50%;
}

.profileInfoContent {
    display: inline-block;
    width: 40%;
    margin-left: 15px;
}
/* End of Profile Page Stylings */

.typeTableDropDown {
    margin-bottom: 5%;
    /*width: 35%;
    min-width: 25%;
    max-width: 35%;*/
}

table {
    width: 100% !important;
}

table td {
    white-space: unset !important;
    overflow-wrap: break-word;
}

/*#first th{
    min-width: 307px;
}*/

td.single {
    width: 50%;
}

.changeTableRowProperty{
    height: 0px !important;
    line-height: 47px;

}
.upgradeGridProperty{
    height: 0px !important;
    line-height: 22px;
}
.changeTableProperty{
    display: block;
    min-height: 0 !important;
}
.unAuthenticatedHomeMessage {
    margin-top: 10px;
}

.marketPlacePortalLink:hover {
    color: red;
}

.breadCrumbContent {
    display: inline-block;
}

.breadcrumb-container {
    margin-bottom: 30px;
}

.noBreadCrumb-container {
    display: none;
}

.reportLabel {
    margin-right: 8px !important;
    margin-bottom: 10px !important;
}

.notificationDropDown {
    display: inline-block !important;
}

/*.profileDropDown {
    display: inline-block !important;
}*/

/* Beginning of Notification sytles */
.historyFeedLabel {
    width: initial !important;
}

.feedLabel {
    width: initial !important;
    display: inline-block !important;
    vertical-align: top !important;
    max-width: 20% !important;
}

.feedContent {
    display: inline-block !important;
    max-width: 80% !important;
}

.navLinkButton {
    background-color: transparent !important;
    text-decoration: none !important;
    border: none !important;
    cursor: pointer !important;
    padding: 0 !important;
    margin: 0 !important;
    color: #4183c4 !important;
    font-size: 16px !important;
}

.markReadButton {
    background-color: transparent !important;
    text-decoration: none !important;
    border: none !important;
    cursor: pointer !important;
    padding: 0 !important;
    margin: 0 !important;
    /*opacity: 1 !important;
    color: rgba(0,0,0,.5) !important;*/
    font-size: .96em !important;
}

.markReadIcon {
    margin-right: 0 !important;
    font-size: 1.1em !important;
}

.notificationHeader {
    padding: 1em 1em !important;
}

.notificationCard {
    min-width: 425px !important;
    margin-bottom: 0.2em !important;
}

.notificationCardContent {
    display: block !important;
    position: relative !important;
    white-space: initial !important;
    width: 100% !important;
    max-height: 500px !important;
    overflow-y: auto !important;
    padding: 0 !important;
}

.historyNotificationCard {
    width: 50% !important;
    min-width: 425px !important;
    line-height: 1.0em !important;
}

.historyNotificationCardContent {
    display: block !important;
    position: relative !important;
    white-space: initial !important;
    width: 100% !important;
    max-height: 500px !important;
    overflow-y: auto !important;
    padding: 0 !important;
}

.notificationFeed {
    display: block !important;
    position: relative !important;
    padding: 1em 1em !important;
}

.showMoreNotificationsSegment {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.toastNotificationMessage {
    box-shadow: none !important;
    border: none !important;
}

.notificationPage {
    max-height: 75vh;
    overflow: scroll !important;
    min-width: 100% !important;
}

.notificationPost {
    width: 50%;
}

.notificationPostOptions {
    display: block !important;
}

.notificationRecipientsDropDown {
    display: inline-block !important;
    width: 83%;
}

.publicNotificationCheckBox {
    display: inline-block !important;
    margin-left: 5%;
    width: 10%;
}

.notificationPostButton {
    margin-top: 10px !important;
}

.feedNotificationOpen {
    width: 50%;
}

.feedPostNotification {
    width: 49%;
}

.commentContent {
    display: inline-block !important;
    margin-left: 5em;
}

.avatarAtTop {
    position: absolute !important;
    top: 0 !important;
}

/* End of Notification Styles */

.gridFormButtonsDiv {
    display: block;
    position: relative;
    overflow: auto;
}

.gridFormBackDiv {
    display: inline-block;
    float: left;
}

.gridFormActionDiv {
    display: inline-block;
    float: right;
    position: relative;
    margin: 3px;
    margin-bottom: 10px;
    margin-top: 0px;
}

.letterHeaderDiv {
    display: block;
    margin-top: 10px;
}

.letterHeaderTitleDiv {
    display: inline-block;
}

.gridFormDropdown {
    opacity: 1 !important;
}

.pageLoader {
    padding-top: 50vh !important;
    z-index: 1 !important;
}

.gridLink {
    cursor: pointer !important;
}

.scertFormBackButton {
    margin-bottom: 40px !important;
}

.scertFormButtonsDiv {
    margin-top: 25px !important;
}

.scertAssumptionsDiv {
    margin-top: 60px;
}

.topLevelScertDiv {
    margin-bottom: 30px;
    margin-top: 20px;
}

.halfPageScertRecord {
    /*display: inline-block !important;*/
    /*width: 100px !important;*/
}

.constructionStatusScertSection {
    margin-top: 45px;
}

.assumptionLabels {
    width: 100%;
    margin-left: 5% !important;
    margin-right: 5% !important;
}

.scertTextArea {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

.scertAssumptionsTextArea {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

.scertRadioStyles {
    padding-left: 5% !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.scertStatusClass {
    color: green;
}

.mitigationDownloadLinksSpan {
    display: block;
}

.rfpNtcFormArea {
    margin-top: 35px;
}

.rfpNtcFormDiv {
    margin-top: 15px;
}

.rfpNtcTextArea {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 60%;
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.ntcMainFormSection {
    margin-top: 30px;
}

.rfpMainFormSection {
    margin-top: 30px;
}

.ntcDropDown {
    margin-bottom: 20px;
}

.conditionalNtcCheckbox {
    margin-bottom: 25px;
}

.upgradeCreateInput {
    min-width: 45%;
    width: 45%;
    display: inline-block;
    margin-right: 4%;
}

.upgradeFormRow {
    display: block;
    margin-bottom: 30px;
}

.tracWebFormButtonDiv {
    margin-top: 15px;
}

.tracWebFormLabel {
    display: inline-block !important;
    margin-right: 15px !important;
}

.tracWebFormToolTip {
    display: inline-block;
}

.tracWebFormCheckBox {
    display: block !important;
    margin-top: 5px;
}

.wrappedTab{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.rfpInputDiv {
    margin-top: 15px;
}

.inlineFormField {
    display: inline-block !important;
    margin-right: 15px !important;
}

.toast-success-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: #FFFFFF !important;
    border: 1px solid #34A853 !important;
    box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.custom-info-toast {
    color: #ffffff !important;
    background-color: #99ccff !important;
}

/*.ntcRecordPage {
    position: relative !important;
    display: none !important;
}*/

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled{
    opacity: 1 !important;
    text-shadow: 0 0 black;
}

.ntcLetterEditor {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 20px;
}

.ntcLetterEditor p {
    border: 0px;
    margin: 0px;
    padding: 0px;
}

.ProseMirror-trailingBreak {
    display: none;
}

.rowC{display:flex; flex-direction:row;}